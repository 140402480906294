import React, { useState, useEffect, useRef}  from 'react';

import classNames from 'classnames';

import { Container, Row, Button, Card, Form} from 'react-bootstrap';
import axios from 'axios';

import style from "./Register.module.css";

import {
    BrowserRouter as Router,
    useHistory
  } from "react-router-dom";

import { useQuery } from "../../../Utils/RouteUtil";

function Register() {

    useEffect(() => {
        inputName.current.focus();
    },[]);

    let history = useHistory();

    const inputName = useRef(null);
    const inputEmail = useRef(null);
    const inputPassword = useRef(null);
    const inputRepeatPassword = useRef(null);

    const [formValidation, setFormValidation] = useState({
        name: false,
        email: false,
        password: false,
        passwordErrorMessage: null,
        repeatPassword: false,
        repeatPasswordErrorMessage: null,
        serverError: false,
        serverErrorMessage: null
     });

    const checkValidation = (e) => {
        setFormValidation({
            ...formValidation,
            [e.target.name]: false
        })
    }

    const onHandleErrorMessage = (e) => {
        setFormValidation({
            ...formValidation,
            [e.name]: true,
            [e.errorField]: e.errorMessage 
        })
    }

    const onChange = (e) => {
        checkValidation(e);
        setFormInput({
            ...formInput,
            [e.target.name]: e.target.value
        })
    }

    const [formInput, setFormInput] = useState({
        name: "",
        email: "",
        password: "",
        repeatPassword: ""
     });

    const validatePassword = (password) => {
        if(password.length == "") return "Por favor, digite sua senha.";
        if(password.length < 8) return "Senha deve ter pelo menos 8 caracteres.";
        return "";
    }

    const onSubmit = (e) => {

        let error = false;
        let errors = {};

        if(formInput.repeatPassword == "") { errors.repeatPassword = true; error = true; errors.repeatPasswordErrorMessage = "Por favor, informe novamente a senha."; }
        if(formInput.password == "") { errors.password = true; error = true; errors.passwordErrorMessage = "Por favor, informe a senha."; }
        if(formInput.email == "") { errors.email = true; error = true; }
        if(formInput.name == "") { errors.name = true; error = true; }
        
        if(formInput.repeatPassword == "") inputRepeatPassword.current.focus();
        
        if(validatePassword(formInput.password) !== "") {
            error = true;
            errors.password = true;
            errors.passwordErrorMessage = validatePassword(formInput.password);
            inputPassword.current.focus();
        }

        if(formInput.email == "") inputEmail.current.focus();
        if(formInput.name == "") inputName.current.focus();

        // passwords not equal
        if(formInput.password !== formInput.repeatPassword) {
            error = true;
            errors.password = true;
            errors.passwordErrorMessage = "Senhas devem ser iguais.";
            errors.repeatPassword = true;
            errors.repeatPasswordErrorMessage = "Senhas devem ser iguais.";
            inputPassword.current.focus();
        }
                
        setFormValidation({...formValidation, ...errors});

        if(error) return;

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/register', {
            name: formInput.name,
            email: formInput.email,
            password: formInput.password
        })
        .then((response) => {
            history.push("/entrar");
        })
        .catch((error) => {
            console.log(error.toJSON());
            errors.serverError = true;
            errors.serverErrorMessage = error.message;
            setFormValidation({...formValidation, ...errors});
        });    


    }
 
    return (
        <div className={style.login_page}>
            <Container className={style.container_login}>

                <Card style={{ width: '28rem'}}>
                    
                    <Card.Body>
                        <Card.Title className={style.card_title}>Registrar</Card.Title>
                        <Card.Text style={{ marginTop: '22px'}}>
                           <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control ref={inputName} onChange={onChange} value={formInput.name} name="name" className={style.input_register} type="text" placeholder="Nome" />
                                    {formValidation.name &&
                                        <Form.Text className={style.error_text} muted>
                                         Por favor, digite seu nome.
                                        </Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control ref={inputEmail} onChange={onChange} value={formInput.email} name="email" className={style.input_register} type="email" placeholder="Email" />
                                    {formValidation.email &&
                                        <Form.Text className={style.error_text} muted>
                                         Por favor, digite seu email.
                                        </Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control ref={inputPassword} onChange={onChange} value={formInput.password} name="password" className={style.input_register} type="password" placeholder="Senha" />
                                    {formValidation.password &&
                                        <Form.Text className={style.error_text} muted>
                                         {formValidation.passwordErrorMessage}
                                        </Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control ref={inputRepeatPassword} onChange={onChange} value={formInput.repeatPassword} name="repeatPassword" className={style.input_register} type="password" placeholder="Repetir senha" />
                                    {formValidation.repeatPassword &&
                                        <Form.Text className={style.error_text} muted>
                                        {formValidation.repeatPasswordErrorMessage}
                                        </Form.Text>
                                    }
                                </Form.Group>
                            </Form>
                        </Card.Text>
                        {formValidation.serverError &&
                            <div className={style.server_error_message}>
                                <p>{formValidation.serverErrorMessage} Usuário já cadastrado</p>
                            </div>
                        }
                        <div className={style.button_container}>
                         <Button variant="success" onClick={onSubmit} size="lg">Cadastrar</Button>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )    

}

export default Register;