import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { Icon } from 'react-icons-kit'
import {plus} from 'react-icons-kit/icomoon/plus'
import {trashO} from 'react-icons-kit/fa/trashO'
import Pagination from 'react-bootstrap/Pagination';

import CourseModal from './CourseModal';
import Ad from "../../../Ad";

import mainStyle from '../Curriculo.module.css';
import style from './Course.module.css';
import { act } from 'react-dom/test-utils';


function Course(params) {
    const [items, setItems] = useState(0);
    const [active, setActive] = useState(0);

    const [showModal, setShowModal] = useState(false);
    const [validation, setValidation] = useState([]);

    const inputCourseName = useRef(null);
    const inputPlace = useRef(null);
    const inputYear = useRef(null);

    const handleModal = () => {
        setShowModal(!showModal);
    }

    useEffect(() => {
        setItems(params.data.courses.length);
        setValidation(params.validation.course);
        if(params.data.courses.length > 0)
        setActive(0);
    }, []);

    useEffect(() => {
        if(items > 0 && params.width >= 990)
            inputCourseName.current.focus();
    }, [items]);

    const addValidation = () => {
        var newValidation = {
            year: false,
            courseName: false,
            place: false
         }
         let validation_e = validation;

         validation_e.push(newValidation);

         setValidation(validation_e);

         params.onChangeValidation({course: validation_e});
    }

    const addItem = () => {
      if(items < 9) {
         // setting a new experience
         var courses = params.data.courses;

         var newCourse = {
            year: "",
            courseName: "",
            place: ""
         }
 
         courses.push(newCourse);
         addValidation();
 
         let event = {
             target: {name: "courses", value: courses}
         }
 
        params.onChange(event);

        setItems(items + 1);
        setActive(items);
      }
    }  
    
    const cleanValidation = (index) => {
        let arrValidation = validation;
        arrValidation[index].year = false;
        arrValidation[index].courseName = false;
        arrValidation[index].place = false;
        setValidation(arrValidation);
    }
    
    const rmItem = () => {
      if(items > 0) {

        // setting a new experience
        var courses = params.data.courses;

        cleanValidation(active);
        courses.splice(active, 1);

        let event = {
            target: {name: "courses", value: courses}
        }

        params.onChange(event);

        setItems(items - 1);
        let newActive = active == 0 ? active : active - 1;
        setActive(newActive);
      }
    } 

    useEffect(() => {
    }, [active]);

    const changeActive = (index) => {
      setActive(index);
    }

    const handleErrorUpdate = (e) => {

        let arrValidation = validation;

        if(e) {
            if(e.target.name.includes(".")) {
                arrValidation[active][e.target.name.split(".")[1]] = false;
                setValidation(arrValidation);
            } else {
                arrValidation[active][e.target.name] = false;
                setValidation(arrValidation);    
            }
        }

        let event = {
            target: {name: "courses", value: params.data.courses}
        }

        params.onChange(event);
    }

    const handleCourseChange = (e) => {
        // setting a new experience
        var courses = params.data.courses;

        courses[active][e.target.name] = e.target.value;

        let event = {
            target: {name: "courses", value: courses}
        }

        handleErrorUpdate(e);

        params.onChange(event);
    }

    const renderItems = () => {
        let pagination = [];

        for(var i = 0; i < items; i++) {
          let key = i;
          pagination.push(
              <Pagination.Item 
                    key={key} 
                    onClick={() => changeActive(key)}
                    active={key == active}>
                {i + 1}
              </Pagination.Item>
          )
        }

        return (
          pagination
        )
        
    }


    const handleNextStep = () => {
        let isNotValid = false;
        let arrValidation = validation;
        let indexErrorValidation = -1;

        for(let i = 0; i < params.data.courses.length; i++) {
            if(params.data.courses[i].place == "") {
                arrValidation[i].place = true;       
                inputPlace.current.focus();
                isNotValid = true;
             }

             if(params.data.courses[i].year == "") {
                arrValidation[i].year = true;   
                inputYear.current.focus();    
                isNotValid = true;
             }

             if(params.data.courses[i].courseName == "") {                 
                 arrValidation[i].courseName = true;    
                 inputCourseName.current.focus();             
                 isNotValid = true;
             }

             if(isNotValid) {
                 indexErrorValidation = i;
                 break;
             }
         }

        if(isNotValid) {            
            setValidation(arrValidation);
            handleErrorUpdate();
            setActive(indexErrorValidation);
            return;
        }

        params.nextStep({course: validation});
    }

    const handlePreviusStep = () => {
        params.previousStep({course: validation});
    }

    return (
        <>
        <CourseModal show={showModal} handleClose={handleModal} />
        <div className={mainStyle.component}>
            <h1 className={mainStyle.component_title}>Cursos: </h1>
            <div>
                <Badge variant="secondary" className={mainStyle.badge_info} onClick={handleModal}>AJUDA</Badge>
                {params.width < 990 &&
                <Badge variant="secondary" className={mainStyle.badge_download} onClick={params.showCv} >VER CURRÍCULO</Badge>
                }
                   
                   <div>                    
                    <Button disabled={items >= 10} variant="secondary" size="sm" onClick={addItem} className={style.btn_add}>
                        <Icon icon={plus} size={12} /> Adicionar
                    </Button>

                    {items > 0 &&
                    <Button disabled={items < 1} variant="danger" size="sm" onClick={rmItem}>
                        <Icon icon={trashO} size={12} /> Remover
                    </Button>
                    }
                  </div>

                  {items > 0 &&   
                    <div className={style.navigation}>
                    <Pagination size="md">                   
                        {
                            renderItems()
                        }
                    </Pagination>
                    </div>
                }
            </div>
            <Form onSubmit={e => { e.preventDefault(); }} className={classNames(mainStyle.inputSection, mainStyle.inputSectionOverFlow)}>  
                {items > 0 &&
                    <>
                        <InputGroup hasValidation>
                            <Form.Group className={mainStyle.form_group}>
                                <Form.Label className={validation[active].courseName ? style.inputError : ""}>Nome do curso:</Form.Label>
                                <Form.Control autoComplete="nope" ref={inputCourseName} value={params.data.courses[active].courseName} onChange={handleCourseChange} name="courseName" type="text" placeholder="Digite o curso..." isInvalid={validation[active].courseName} />
                                <Form.Control.Feedback type="invalid">
                                    Digite o nome do curso.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </InputGroup>

                        <InputGroup hasValidation>
                            <Form.Group className={mainStyle.form_group}>
                                <Form.Label className={validation[active].year ? style.inputError : ""}>Ano da realização:</Form.Label>
                                <Form.Control autoComplete="nope" ref={inputYear} value={params.data.courses[active].year} onChange={handleCourseChange} name="year" type="number" placeholder="Digite o ano que você realizou..." isInvalid={validation[active].year} />
                                <Form.Control.Feedback type="invalid">
                                    Digite o ano de realização do curso.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </InputGroup>

                        <InputGroup hasValidation>
                            <Form.Group className={mainStyle.form_group}>
                                <Form.Label className={validation[active].place ? style.inputError : ""}>Local de realização:</Form.Label>
                                <Form.Control autoComplete="nope" ref={inputPlace} value={params.data.courses[active].place} onChange={handleCourseChange} name="place" type="text" placeholder="Digite o local de realização..." isInvalid={validation[active].place} />
                                <Form.Control.Feedback type="invalid">
                                    Digite o local de realização do curso.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </InputGroup>



                    </>
                }
            </Form>
            <div className={mainStyle.buttonSection}>
                    <Button onClick={handlePreviusStep} className={classNames(mainStyle.btn_control, "float-left", mainStyle.btn_info)} variant="dark">Anterior</Button>
                    <Button onClick={handleNextStep} className={classNames(mainStyle.btn_control, "float-right")} variant="dark">Próximo</Button>
                </div>
        
        </div>
        </>
    );
  }
  
  export default Course;