import React from 'react';

import "@fontsource/open-sans";

// header
import Header from './components/Header/Header';

// home
import Home from './components/Home/Home';

// footer
import Footer from './components/Footer/Footer';

// Curriculo
import Curriculo from './components/Curriculo/Curriculo';

// Login
import Login from './components/pages/Login/Login';

// Register
import Register from './components/pages/Register/Register';

// PasswordRecovery
import PasswordRecovery from './components/pages/PasswordRecovery/PasswordRecovery';

// Password Recovery
import PasswordRecoveryRequest from './components/pages/PasswordRecoveryRequest/PasswordRecoveryRequest';

// Publication
import Publication from './components/pages/Publication/Publication';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import './App.css';

function App() {
  return (
      <Router>
        <Header />

        <Switch>

          {/* home */}
          <Route exact path="/">
             <Home />
             {/* footer */}
             <Footer />
          </Route>

          <Route exact path="/criarcurriculo/">
             <Curriculo />
          </Route>

          <Route exact path="/entrar/">
             <Login />
          </Route>

          <Route exact path="/registrar/">
             <Register />
          </Route>

          <Route exact path="/recuperar_conta/">
             <PasswordRecoveryRequest />
          </Route>

          <Route exact path="/alterar_senha/">
             <PasswordRecovery />
          </Route>

          <Route path="/publicacao/">
             <Publication />
             <Footer />
          </Route>

          <Route path='*' exact={true}>
            <Home />
             {/* footer */}
             <Footer />
          </Route>
          

        </Switch>     
        
      </Router>
  );
}

export default App;
