import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { Icon } from 'react-icons-kit'
import {plus} from 'react-icons-kit/icomoon/plus'
import {trashO} from 'react-icons-kit/fa/trashO'
import Pagination from 'react-bootstrap/Pagination';

import LanguageModal from './LanguageModal';

import Ad from "../../../Ad";

import mainStyle from '../Curriculo.module.css';
import style from './Language.module.css';
import { act } from 'react-dom/test-utils';



function Language(params) {
    const [items, setItems] = useState(0);
    const [active, setActive] = useState(0);

    const [showModal, setShowModal] = useState(false);

    const [validation, setValidation] = useState([]);

    const inputLanguageName = useRef(null);
    const inputLevel = useRef(null);

    const handleModal = () => {
        setShowModal(!showModal);
    }

    useEffect(() => {
        setItems(params.data.languages.length);
        setValidation(params.validation.language);
        if(params.data.languages.length > 0)
        setActive(0);
    }, []);

    useEffect(() => {
        if(items > 0 && params.width >= 990) 
            inputLanguageName.current.focus();
    }, [items])
    
    const addValidation = () => {
        var newValidation = {
            languageName:  false,
            level: false
         }
         let validation_e = validation;

         validation_e.push(newValidation);

         setValidation(validation_e);

         params.onChangeValidation({language: validation_e});
    }

    const addItem = () => {
      if(items < 9) {
         // setting a new experience
         var languages = params.data.languages;

         var newLanguage = {
            language: {languageName: ""},
            level: ""
         }
 
         languages.push(newLanguage);
         addValidation();
 
         let event = {
             target: {name: "languages", value: languages}
         }
 
        params.onChange(event);

        setItems(items + 1);
        setActive(items);
      }
    }    
    
    const rmItem = () => {
      if(items > 0) {

        // setting a new experience
        var languages = params.data.languages;

        cleanValidation(active);
        languages.splice(active, 1);

        let event = {
            target: {name: "languages", value: languages}
        }

        params.onChange(event);

        setItems(items - 1);
        let newActive = active == 0 ? active : active - 1;
        setActive(newActive);
      }
    } 

    const changeActive = (index) => {
      setActive(index);
    }


    const cleanValidation = (index) => {
        let arrValidation = validation;
        arrValidation[index].languageName = false;
        arrValidation[index].level = false;
        setValidation(arrValidation);
    }
    

    const handleErrorUpdate = (e) => {

        let arrValidation = validation;

        if(e) {
            if(e.target.name.includes(".")) {
                arrValidation[active][e.target.name.split(".")[1]] = false;
                setValidation(arrValidation);
            } else {
                arrValidation[active][e.target.name] = false;
                setValidation(arrValidation);    
            }
        }

        let event = {
            target: {name: "languages", value: params.data.languages}
        }

        params.onChange(event);
    }

    const handleLanguageChange = (e) => {
        // setting a new experience
        var languages = params.data.languages;

        if(e.target.name.includes(".")) {
            languages[active][e.target.name.split(".")[0]] = {[e.target.name.split(".")[1]]: e.target.value};
            
        } else {
            languages[active][e.target.name] = e.target.value;
        }

        handleErrorUpdate(e);

        let event = {
            target: {name: "languages", value: languages}
        }

        params.onChange(event);
    }

    const renderItems = () => {
        let pagination = [];

        for(var i = 0; i < items; i++) {
          let key = i;
          pagination.push(
              <Pagination.Item 
                    key={key} 
                    onClick={() => changeActive(key)}
                    active={key == active}>
                {i + 1}
              </Pagination.Item>
          )
        }

        return (
          pagination
        )
        
    }

    const handleNextStep = () => {

        let isNotValid = false;
        let newValidation = {};
        let arrValidation = validation;
        let indexErrorValidation = -1;

        for(let i = 0; i < params.data.languages.length; i++) {            

            if(params.data.languages[i].level == "") {
                arrValidation[i].level = true;       
                inputLevel.current.focus();
                isNotValid = true;
             }

             if(params.data.languages[i].language.languageName == "") {                 
                 arrValidation[i].languageName = true;    
                 inputLanguageName.current.focus();
                 isNotValid = true;
             }

             if(isNotValid) {
                 indexErrorValidation = i;
                 break;
             }
         }

        if(isNotValid) {            
            setValidation(arrValidation);
            handleErrorUpdate();
            setActive(indexErrorValidation);
            return;
        }
    
        params.nextStep({language: validation});

    }

    const handlePreviusStep = () => {
        params.previousStep({language: validation});
    }

    return (
        <>
        <LanguageModal show={showModal} handleClose={handleModal} />
        <div className={mainStyle.component}>
            <h1 className={mainStyle.component_title}>Idiomas: </h1>
            <div>
                <Badge variant="secondary" className={mainStyle.badge_info} onClick={handleModal}>AJUDA</Badge>
                {params.width < 990 &&
                <Badge variant="secondary" className={mainStyle.badge_download} onClick={params.showCv} >VER CURRÍCULO</Badge>
                }
                   
                   <div>                    
                    <Button disabled={items >= 10} variant="secondary" size="sm" onClick={addItem} className={style.btn_add}>
                        <Icon icon={plus} size={12} /> Adicionar
                    </Button>

                    {items > 0 &&
                    <Button disabled={items < 1} variant="danger" size="sm" onClick={rmItem}>
                        <Icon icon={trashO} size={12} /> Remover
                    </Button>
                    }
                  </div>

                  {items > 0 &&   
                    <div className={style.navigation}>
                    <Pagination size="md">                   
                        {
                            renderItems()
                        }
                    </Pagination>
                    </div>
                }
            </div>
            <Form onSubmit={e => { e.preventDefault(); }} className={classNames(mainStyle.inputSection, mainStyle.inputSectionOverFlow)}>   
                {items > 0 &&
                    <>
                        <InputGroup hasValidation>
                            <Form.Group className={mainStyle.form_group}>
                                    <Form.Label className={validation[active].languageName ? style.inputError : ""}>Idioma:</Form.Label>
                                    <Form.Control ref={inputLanguageName} isInvalid={validation[active].languageName} name="language.languageName" as="select" custom value={params.data.languages[active].language.languageName} onChange={handleLanguageChange}>
                                        <option value="">Selecione um idioma</option>
                                        <option value="Inglês">Inglês</option>
                                        <option value="Português">Português</option>
                                        <option value="Espanhol">Espanhol</option>
                                        <option value="Italiano">Italiano</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Selecione um idioma na lista
                                    </Form.Control.Feedback>
                                </Form.Group>
                        </InputGroup>

                        <InputGroup hasValidation>
                            <Form.Group className={mainStyle.form_group}>
                                <Form.Label className={validation[active].level ? style.inputError : ""}>Nível:</Form.Label>
                                <Form.Control ref={inputLevel} isInvalid={validation[active].level} name="level" as="select" custom value={params.data.languages[active].level} onChange={handleLanguageChange}>
                                    <option value="">Selecione um estado</option>
                                    <option value="Básico">Básico</option>
                                    <option value="Intermediário">Intermediário</option>
                                    <option value="Avançado">Avançado</option>
                                    <option value="Fluente">Fluente</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Selecione um nível de proficiência
                                </Form.Control.Feedback>
                            </Form.Group>
                        </InputGroup> 
                    </>
                }
            </Form>            

            <div className={mainStyle.buttonSection}>
                    <Button onClick={handlePreviusStep} className={classNames(mainStyle.btn_control, "float-left", mainStyle.btn_info)} variant="dark">Anterior</Button>
                    <Button onClick={handleNextStep} className={classNames(mainStyle.btn_control, "float-right")} variant="dark">Próximo</Button>
                </div>
        
        </div>
        </>
    );
  }
  
  export default Language;