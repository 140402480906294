import React, { useState } from 'react';

import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';
import style from './Qualification.module.css';


function QualificationModal(params) {

    const [examples, setExemples] = useState(
        [
        "Minhas qualificações incluem excelentes habilidades de comunicação e relacionamento interpessoal, disponibilidade e disposição para trabalhar com o público, proatividade, criatividade e uma atitude colaborativa e positiva em equipe.",
        "Possuo habilidades avançadas em pacote Office (Excel, Word, PowerPoint), experiência na utilização de gerenciadores de e-mail, excelente digitação com velocidade acima da média, e habilidades de organização e gestão de documentos.",
        "Sou uma pessoa receptiva e comunicativa, com disponibilidade para viajar e trabalhar aos fins de semana. Possuo excelente oratória e habilidade em falar para grandes públicos, combinando um estilo comunicativo e persuasivo para engajar a audiência.",
        "Sou capaz de trabalhar com eficiência sob pressão, aprender rapidamente e trabalhar em equipe, demonstrando resiliência em situações desafiadoras. Minha experiência em vendas, telemarketing e consultoria de beleza me proporcionou habilidades valiosas em atendimento ao cliente, negociação e resolução de problemas."
    ]);

    const selectObjective = (option) => {

        let event = {
            target: {name: "qualifications", value: examples[option]}
        }    

        params.onChange(event);
        params.handleClose();
    }

    return ( 
        <Modal show={params.show} onHide={params.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Resumo das qualificações</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p><b>Destaque suas principais qualificações, habilidades e competências que o tornam apto para a vaga em questão, e adapte-as de acordo com o perfil da empresa. Descreva suas habilidades com ferramentas e métodos relevantes. Veja exemplos:</b></p>
                <p><span>{examples[0]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(0)}>Usar exemplo</button></p>
                <p><span>{examples[1]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(1)}>Usar exemplo</button></p>
                <p><span>{examples[2]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(2)}>Usar exemplo</button></p>
                <p><span>{examples[3]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(3)}>Usar exemplo</button></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={params.handleClose}>
                 Fechar
                </Button>
            </Modal.Footer>
          </Modal>
    );
  }
  
  export default QualificationModal;