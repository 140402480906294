import React, { useState, useEffect}  from 'react';

import classNames from 'classnames';

import style from "./Footer.module.css";
import { Container, Row, Button, Col } from 'react-bootstrap';

import {
    Link
  } from "react-router-dom";

function Footer() {
 
    return (
        <div className={style.footer}>

            <div classname={style.footer_top}>
                <Container className={style.footer_main}>
                    <Row>
                        <Col lg={9} className={style.footer_info}>
                            <p>
                                <h2>Gerar Currículo</h2>
                            </p>
                            <p>
                            Crie um currículo profissional de forma simples e eficiente com nossa plataforma online
                            </p>
                        </Col>

                        {/* <Col lg={3}>
                            <h1 className={style.center_title}>Link Úteis</h1>
                            <ul className={style.center_content}>
                                <li><a href="#">Início</a></li>
                                <li><a href="#">Planilhas</a></li>
                            </ul>
                        </Col> */}

                        <Col lg={3}>
                            <h1 className={style.center_title}>Links Úteis</h1>
                            <ul className={style.center_content}>
                                <Link to={`/criarcurriculo`}>
                                <li><a href="#">Criar currículo</a></li>
                                </Link>
                                <Link to={`/entrar`}>
                                <li><a href="#">Entrar</a></li>
                                </Link>
                                <Link to={`/criarcurriculo`}>
                                <li><a href="#">Política de privicidade</a></li>
                                </Link>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={style.footer_copyrigth}>
                <Container>
                    Gerar Currículo 2024
                </Container>
            </div>

        </div>
    )    

}

export default Footer;