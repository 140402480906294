import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import ContactModal from './ContactModal';

import Ad from "../../../Ad";

import FormControl from 'react-bootstrap/FormControl';

import MaskedFormControl from 'react-bootstrap-maskedinput'
import MaskedInput from "react-maskedinput"; 

import mainStyle from '../Curriculo.module.css';
import style from './Contact.module.css';


function Contact(params) {

    const [showModal, setShowModal] = useState(false);

    const handleModal = () => {
        setShowModal(!showModal);
    }

    const inputTelephoneNumber = useRef(null);

    useEffect(() => {
        if(params.width >= 990)
           inputTelephoneNumber.current.focus();
    }, []);

    return (
        <>
        <ContactModal show={showModal} handleClose={handleModal} />
        <div className={mainStyle.component}>
            <h1 className={mainStyle.component_title}>Dados de Contato </h1>
            <div>
            <Badge variant="secondary" className={mainStyle.badge_info} onClick={handleModal}>AJUDA</Badge>
            {params.width < 990 &&
             <Badge variant="secondary" className={mainStyle.badge_download} onClick={params.showCv} >VER CURRÍCULO</Badge>
            }
            </div>
            <Form onSubmit={e => { e.preventDefault(); }} className={classNames(mainStyle.inputSection, mainStyle.inputSectionOverFlow)}>   
                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label>Telefone:</Form.Label>
                        <FormControl autoComplete="nope"  ref={inputTelephoneNumber} as={MaskedInput} mask='(11) 1111-1111' value={params.data.contact.telephoneNumber} onChange={params.onChange} name="contact.telephoneNumber" type="text" placeholder="Digite seu telefone..." isInvalid={false} />
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup> 

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label>Celular:</Form.Label>
                        <FormControl autoComplete="nope" as={MaskedInput} mask='(11) 11111-1111' value={params.data.contact.telephoneNumberTwo} onChange={params.onChange} name="contact.telephoneNumberTwo" type="text" placeholder="Digite seu celular..." isInvalid={false} />
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup> 

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label>Email:</Form.Label>
                        <Form.Control autoComplete="nope" value={params.data.contact.email} onChange={params.onChange} name="contact.email" type="email" placeholder="Digite seu email..." isInvalid={false} />
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>  

            </Form>
           

            <div className={mainStyle.buttonSection}> 
                    <Button onClick={params.previousStep} className={classNames(mainStyle.btn_control, "float-left", mainStyle.btn_info)} variant="dark">Anterior</Button>
                    <Button onClick={params.nextStep} className={classNames(mainStyle.btn_control, "float-right")} variant="dark">Próximo</Button>
            </div>
        
        </div>
        </>
    );
  }
  
  export default Contact;