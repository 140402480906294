import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import Button from 'react-bootstrap/Button';

import Ad from '../../../Ad';
import mainStyle from '../Curriculo.module.css';
import style from './Advertising.module.css';


function Advertising(params) {

    const [adNumber, setAdNumber] = useState(Math.floor(Math.random() * 5));

    return (
        <>
            <div className={mainStyle.component}>
            <h1 className={mainStyle.component_title}>Anúncio</h1>

            <div className={style.adSection}>
                 <Ad ad_number={adNumber} width={params.width} />
            </div>

            <div className={mainStyle.buttonSection}> 
                    <Button onClick={params.previousStep} className={classNames(mainStyle.btn_control, "float-left", mainStyle.btn_info)} variant="dark">Anterior</Button>
                    <Button onClick={params.createCv ? params.createCv : params.nextStep} className={classNames(mainStyle.btn_control, "float-right")} variant="dark">
                        {params.createCv && <span>Gerar Currículo</span>}
                        {!params.createCv && <span>Próximo</span>}
                    </Button>
            </div>

            </div>
        </>
    )


}


export default Advertising;