import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import QualificationModal from './QualificationModal';

import Ad from "../../../Ad";

import mainStyle from '../Curriculo.module.css';
import style from './Qualification.module.css';


function Qualification(params) {

    const [showModal, setShowModal] = useState(false);

    const [validation, setValidation] = useState({
        qualifications: false
    });

    const inputQualification = useRef(null);

    const handleModal = () => {
        setShowModal(!showModal);
    }

    useEffect(() => {
        if(params.width >= 990)
            inputQualification.current.focus();
    }, [])

    const handleNextStep = () => {

        let isNotValid = false;
        let newValidation = {};

        if(params.data.qualifications == "") {
            isNotValid = true;
            newValidation.qualifications = true;
            inputQualification.current.focus();
        }

        if(isNotValid) {
            setValidation({...validation, ...newValidation});
            return;  
        }

        params.nextStep();

    }

    const handleInputChange = (e) => {
        setValidation({...validation, [e.target.name]: false});
        params.onChange(e);
    }

    const handleModalSelection = (e) => {
        setValidation({...validation, [e.target.name]: false});
        params.onChange(e);
    }

    return (
        <>
        <QualificationModal show={showModal} handleClose={handleModal} onChange={handleModalSelection} />
        <div className={mainStyle.component}>
            <h1 className={mainStyle.component_title}>Qualificações </h1>
            <div>
                <Badge variant="secondary" className={mainStyle.badge_info} onClick={handleModal}>AJUDA</Badge>
                {params.width < 990 &&
                <Badge variant="secondary" className={mainStyle.badge_download} onClick={params.showCv} >VER CURRÍCULO</Badge>
                }
            </div>
            <Form onSubmit={e => { e.preventDefault(); }} className={classNames(mainStyle.inputSection, mainStyle.inputSectionOverFlow)}>
                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation.qualifications ? style.inputError : ""}>Descrição das qualificações:</Form.Label>
                        <Form.Control value={params.data.qualifications} 
                                      onChange={handleInputChange} 
                                      ref={inputQualification}
                                      as="textarea" 
                                      rows={4}
                                      name="qualifications" 
                                      type="text"  
                                      placeholder="Digite suas principais qualificações..." 
                                      isInvalid={validation.qualifications} 
                        />
                        <Form.Control.Feedback type="invalid">
                            Digite um resumo das suas principais qualificações. Se precisar, use um dos modelos clicando em Ajuda.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup> 
            </Form>

            <div className={mainStyle.buttonSection}>
                    <Button onClick={params.previousStep} className={classNames(mainStyle.btn_control, "float-left", mainStyle.btn_info)} variant="dark">Anterior</Button>
                    <Button onClick={handleNextStep} className={classNames(mainStyle.btn_control, "float-right")} variant="dark">Próximo</Button>
                </div>
        
        </div>
        </>
    );
  }
  
  export default Qualification;