import React, { useState, useEffect, useRef}  from 'react';

import { Container, Col, Row } from "react-bootstrap";


import Ad from "../../../../Ad";

import style from "./Post.module.css";

function CriandoUmCurriculoProfissional() {

    const [adNumber, setAdNumber] = useState(Math.floor(Math.random() * 5));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Row className={style.publication_content}>
          <Col lg="8">
            <h1 className={style.post_title}>Criando um currículo profissional </h1>

            <p>Um currículo profissional é uma das principais ferramentas que você tem para se apresentar a potenciais empregadores e mostrar por que você é a pessoa certa para o cargo que deseja. É importante levar a sério a criação do seu currículo, pois ele pode ser a primeira coisa que um recrutador verá sobre você e, portanto, é fundamental que ele seja claro, conciso e convincente. Aqui estão algumas dicas para ajudá-lo a criar um currículo profissional de sucesso.  </p>
          
            <h2 className={style.publication_sub_title}>1. Escolha um modelo adequado </h2>
            <p>Um modelo de currículo bem projetado pode ajudar a tornar seu currículo mais atraente e profissional. A aparência do currículo é a primeira impressão que o empregador terá de você, portanto, é importante que ele tenha uma aparência agradável e organizada. Alguns modelos de currículo são projetados para destacar informações importantes, como suas habilidades e realizações. Isso ajuda a atrair a atenção do empregador para as informações mais relevantes do seu perfil profissional.  </p> 

            <h2 className={style.publication_sub_title}>2. Mantenha o formato simples e limpo </h2>
            <p>Manter o currículo em um formato simples e limpo é importante por várias razões. Em primeiro lugar, isso garante que o seu currículo seja fácil de ler e entender para o recrutador. Quanto mais simples e organizado o seu currículo for, mais provável é que o recrutador se interesse por ele e queira saber mais sobre suas habilidades e experiências. Além disso, um currículo limpo e bem estruturado destaca melhor suas realizações e responsabilidades anteriores, ajudando a criar uma imagem positiva de você para o recrutador.</p> 

            <h2 className={style.publication_sub_title}>3. Seja objetivo e claro</h2> 
            <p>Ser objetivo e claro em seu currículo é importante porque permite que o recrutador ou empregador compreenda rapidamente suas habilidades, experiências e objetivos profissionais. Além disso, um currículo bem escrito e objetivo é mais fácil de ser lido e avaliado, aumentando as chances de chamar a atenção do recrutador e se destacar entre outros candidatos. </p> 
            <p>Quando você é objetivo e claro no currículo, você evita confusão ou interpretações equivocadas sobre suas habilidades e experiências, e destaca suas qualificações relevantes para o cargo que está buscando. Isso ajuda o recrutador a avaliar se você é o candidato adequado para o trabalho e se está em linha com o perfil procurado pela empresa.  </p>

            <h2 className={style.publication_sub_title}>4. Adicione suas qualificações </h2>  
            <p>Inclua suas qualificações, como diplomas, cursos, certificações e habilidades relevantes para o cargo em que está se candidatando. Ao incluir suas qualificações em seu currículo, você está comprovando seu conhecimento em determinadas áreas. Isso mostra que você tem as habilidades e competências necessárias para desempenhar bem o trabalho. Além disso, pode ajudar a diferenciá-lo dos outros candidatos que não possuem as mesmas qualificações. Isso pode aumentar suas chances de ser selecionado para uma entrevista de emprego. </p> 

            <h2 className={style.publication_sub_title}>5. Destaque sua experiência relevante </h2>  
            <p>Ao mencionar suas habilidades relevantes para a vaga em questão, você está mostrando ao empregador que você tem as habilidades necessárias para realizar as tarefas associadas ao cargo. Incluindo resultados que você alcançou em empregos anteriores, você está demonstrando seu valor como um profissional competente e produtivo. Isso pode ajudar a destacá-lo entre outros candidatos. Muitas vezes, os empregadores recebem uma grande quantidade de currículos para uma única vaga. Ao incluir informações relevantes para o cargo, você facilita o processo de triagem do empregador, tornando seu currículo mais fácil de ser considerado para a próxima fase do processo seletivo. </p> 

            <h2 className={style.publication_sub_title}>6. Mostre seus resultados </h2> 
            <p>Ao incluir resultados mensuráveis em seu currículo, você está comprovando suas habilidades e competências. Isso mostra que você tem um histórico de sucesso e pode ajudar a diferenciá-lo de outros candidatos. Os resultados mensuráveis demonstram o impacto que você teve nas empresas anteriores em que trabalhou. Isso é importante porque os empregadores querem saber como você pode agregar valor à organização e contribuir para o sucesso da empresa. </p> 

            <h2 className={style.publication_sub_title}>7. Revise e revise novamente </h2> 
            <p>Antes de enviar seu currículo, certifique-se de que ele esteja livre de erros de ortografia e gramática. Peça a alguém de confiança para revisá-lo também. Erros de ortografia e gramática podem afetar a impressão que o empregador tem de você. Eles podem perceber que você não se importa com a qualidade do seu trabalho ou que você não tem boas habilidades de comunicação. É importante mostrar que você é um profissional que presta atenção aos detalhes e se comunica de forma clara e eficaz. Por isso, peça a alguém de confiança para revisar seu currículo para ajudar a identificar erros que você pode ter perdido. Uma segunda opinião pode ajudá-lo a melhorar a qualidade do seu currículo e aumentar suas chances de sucesso. </p> 

            <h2 className={style.publication_sub_title}>Conclusão </h2> 
            <p>Lembre-se de que o seu currículo é a sua carta de apresentação para o mundo do trabalho. Seguindo essas dicas, você poderá criar um currículo profissional atraente e eficaz que o ajudará a se destacar e a conquistar o emprego dos seus sonhos.   </p> 

            <p>Com base nessas dicas, por que não aproveitar nossa ferramenta para criar um currículo profissional já formatado? Isso tornará mais fácil aplicar todas as dicas que discutimos neste artigo. Clique no botão abaixo para começar a criar seu currículo agora mesmo e aumente suas chances de conquistar o emprego dos seus sonhos. </p> 

          </Col>

          <Col lg="3">
            <div className={style.div_sticky}>
              <Ad ad_number={adNumber} />
            </div>
          </Col>
        </Row>
    );

}

export default CriandoUmCurriculoProfissional; 