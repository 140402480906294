import React, { useState, useEffect, useRef}  from 'react';

import style from "./Publication.module.css";

import { Container, Breadcrumb, Row, Col } from "react-bootstrap";

import Post from './Post/Post';

import {
    useParams,
    useRouteMatch,
    Switch,
    Route,
    Link
  } from "react-router-dom";

function Publication() {

    let { path, url } = useRouteMatch();

    let { code } = useParams();
  
    return (

      <Switch>
        <Route exact  path={`${path}`}>    
            <Container className={style.publication_body}>                 
                <Breadcrumb className={style.breadcrumb}>
                    <Breadcrumb.Item><Link to={`/`}>Início</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Publicações</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Publicações</h1>
                    <Row>
                        <Col lg="4">
                            <Link to={`/publicacao/boas-praticas-para-uma-entrevista-bem-sucedida`}>
                                <div className={style.publication_card}>
                                        <span className={style.publication_card_image}>
                                            <img src="images/publication2.png"/>
                                        </span>
                                        <h2 className={style.publication_card_title}>Boas práticas para uma entrevista bem-sucedida </h2>
                                        <p className={style.publication_card_description}>Dicas valiosas para se preparar e ter sucesso em uma entrevista de emprego.</p>
                                </div>
                            </Link>
                        </Col> 

                        <Col lg="4">
                            <Link to={`/publicacao/criando-um-curriculo-profissional`}>
                                <div className={style.publication_card}>
                                        <span className={style.publication_card_image}>
                                            <img src="images/publication3.png"/>
                                        </span>
                                        <h2 className={style.publication_card_title}>Criando um currículo profissional</h2>
                                        <p className={style.publication_card_description}>Nesta publicação, apresentamos um passo a passo de como criar um currículo profissional utilizando a nossa ferramenta.</p>
                                </div>
                            </Link>
                        </Col>
                            <Col lg="4">       
                                <Link to={`/publicacao/como-se-preparar-para-o-mercado-de-trabalho`}>                 
                                    <div className={style.publication_card}>
                                            <span className={style.publication_card_image}>
                                                <img src="images/publication1.png"/>
                                            </span>
                                            <h2 className={style.publication_card_title}>Como se preparar para o mercado de trabalho</h2>
                                            <p className={style.publication_card_description}>Nesta postagem, você encontrará dicas valiosas sobre como se preparar para o mercado de trabalho. </p>
                                    </div>
                                </Link>        
                            </Col>   
                    </Row>
            </Container>
        </Route>

        <Route exact path={`${path}:code`}>
            <Post />
        </Route>    

      </Switch>
    
    );
  }

export default Publication;