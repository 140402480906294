import React, { useState, useEffect}  from 'react';

import { Navbar, Nav, NavDropdown, Container, Row, Col } from 'react-bootstrap';

import { Icon } from 'react-icons-kit'
import { user } from 'react-icons-kit/feather/user'

import classNames from 'classnames';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useHistory
  } from "react-router-dom";


import style from "./Header.module.css";
import styleCv from "./HeaderCv.module.css";



function Header() {
   
   let history = useHistory();

   const [pathName, setPathName] = useState(history.location.pathname);

  const [navbarExpand, setNavbarExpand] = useState(false);

  const location = useLocation();

  const onSelectOption = () => {
    setNavbarExpand(false);
  }

  const onClick = () => {
    setNavbarExpand(!navbarExpand);
  }

  useEffect(() => {
      setPathName(history.location.pathname);
  },[location]);
 
    return (
        <div classNames={pathName === "/" ? style.wrap_stricky : styleCv.wrap_stricky}>
        <Navbar expanded={navbarExpand} expand="lg" fixed={pathName === "/" ? "top" : false} className={classNames(
          pathName === "/" ? style.navbar : styleCv.navbar
          )}>
            
          <Container>
              <Link to={`/`}>
                <Navbar.Brand className={style.website_name}>
                    <span><img src="/images/logo.jpg" width="28" heigth="32"/></span>
                    <span>Gerar Currículo</span>
                </Navbar.Brand>
              </Link>
              {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={onClick} /> */}
               <Navbar id="navbarsExample07" className={style.login_navbar}>
                <Nav id="menu">
                  <Nav.Link className={style.login_button}>
                    <Link to={`/entrar`}>
                       <Icon size={15} icon={user} />Entrar
                    </Link>
                  </Nav.Link>
                  <Nav.Link className={style.register_button}><Link to={`/registrar`}><b>Registrar</b></Link></Nav.Link>
                </Nav>
              </Navbar> 
              </Container>
          </Navbar> 
          <div className={pathName === "/" ? style.header_padding : style.cv_header_padding  }></div>
          </div>
    )    

}

export default Header;