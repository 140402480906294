import React, { useState, useEffect}  from 'react';

import classNames from 'classnames';

import style from "./Home.module.css";
import { Container, Row, Button, Col } from 'react-bootstrap';

import {
    Link
  } from "react-router-dom";

function Home() {
 
    return (
        <div>
            <div className={style.home_background}>
                <Container>
                    <Row>
                        <Col className={style.home_info_col} lg="5">
                            <h1 className={style.title_text}>Crie seu currículo profissional</h1>
                            <p className={style.title_paragraph}>Crie um currículo profissional de forma simples e eficiente com nossa plataforma online</p>
                        </Col>
                        <Col lg="7">      
                             <img width="100%" src="images/Interview-pana.svg" title="Currículo Lattes" alt="Currículo Lattes" />
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container className={style.home_it_works}>
                <h2 className={style.section_title}>
                    Como Funciona
                </h2>
                <Row>
                   <Col>
                       <div className={style.feature_card}>
                            <span className={style.feature_card_icon}>
                                <img src="images/feature1.jpg" width="74" heigth="77"/>
                            </span>
                            <h2 className={style.feature_card_title}>Escolha um modelo</h2>
                            <p className={style.feature_card_description}>Experimente escolher diversos modelos de currículos profissionais.</p>
                       </div>
                   </Col> 
                   <Col>
                        <div className={style.feature_card}>
                            <span className={style.feature_card_icon}>
                                <img src="images/feature2.jpg" width="74" heigth="77"/>
                            </span>
                            <h2 className={style.feature_card_title}>Preencha suas informações</h2>
                            <p className={style.feature_card_description}>Preencha suas informações e seu currículo é formato.</p>
                       </div>
                   </Col> 
                   <Col>
                         <div className={style.feature_card}>
                         <span className={style.feature_card_icon}>
                                <img src="images/feature3.jpg" width="74" heigth="77"/>
                            </span>
                            <h2 className={style.feature_card_title}>Baixe seu currículo</h2>
                            <p className={style.feature_card_description}>Selecione o formato (PDF, Word, e-mail) e baixe seu currículo.</p>
                       </div>
                   </Col> 
                </Row>

                <Row className={style.call_to_action_row}>
                    <Col>
                        <Link to={`/criarcurriculo`}>
                            <Button variant="primary" size="lg" className={style.call_to_action_btn}>
                                Começar Agora
                            </Button>
                        </Link>
                    </Col>
                </Row>

            </Container>

            <div className={style.section_container}>
                <Container className={style.platform_numbers}>
                    <h2 className={style.section_title}>
                    Alguns números da nossa ferramenta
                    </h2>
                    <Row>
                        <Col lg="4">
                            <div className={style.number_card}>
                                    <span className={style.number_card_icon}>
                                        <img src="images/number1.png" width="74" heigth="77"/>
                                    </span>
                                    <h2 className={style.number_card_title}>500+</h2>
                                    <p className={style.number_card_description}>Usuários cadastrados.</p>
                            </div>
                        </Col> 
                        <Col lg="4">
                            <div className={style.number_card}>
                                    <span className={style.number_card_icon}>
                                        <img src="images/number2.png" width="74" heigth="77"/>
                                    </span>
                                    <h2 className={style.number_card_title}>100+</h2>
                                    <p className={style.number_card_description}>Aprovaram a ferramenta.</p>
                            </div>
                        </Col> 
                        <Col lg="4">
                            <div className={style.number_card}>
                                    <span className={style.number_card_icon}>
                                        <img src="images/number3.png" width="74" heigth="77"/>
                                    </span>
                                    <h2 className={style.number_card_title}>5000+</h2>
                                    <p className={style.number_card_description}>Currículos gerados.</p>
                            </div>
                        </Col> 
                    </Row>
                </Container>
            </div>

            <div className={style.section_models}>
                <Container>
                    <h2 className={style.section_title}>
                        Modelos disponíveis
                    </h2>
                    <Row>
                    <Col>
                        <div className={style.model_card}>
                             <img src="images/model1.png" />
                        </div>                               
                    </Col> 
                    <Col>
                        <div className={style.model_card}>
                            <img src="images/model2.png" />
                        </div>
                    </Col> 
                    <Col>
                        <div className={style.model_card}>
                          <img src="images/model3.png" />
                        </div>
                    </Col> 
                    </Row>

                    <Row>
                        <div className={style.model_card_pagination}>
                            <span className={style.pagination_circle}></span>
                            {/* <span className={style.pagination_circle}>1</span>
                            <span className={style.pagination_circle}>1</span> */}
                        </div>
                    </Row>

                </Container>
            </div>

            <div className={style.section_container}>
                <Container className={style.publications}>
                    <h2 className={style.section_title}>
                    Publicações
                    </h2>
                    <Row>
                        <Col lg="4">
                            <Link to={`/publicacao/boas-praticas-para-uma-entrevista-bem-sucedida`}>
                                <div className={style.publication_card}>
                                        <span className={style.publication_card_image}>
                                            <img src="images/publication2.png"/>
                                        </span>
                                        <h2 className={style.publication_card_title}>Boas práticas para uma entrevista bem-sucedida </h2>
                                        <p className={style.publication_card_description}>Dicas valiosas para se preparar e ter sucesso em uma entrevista de emprego.</p>
                                </div>
                            </Link>
                        </Col> 

                        <Col lg="4">
                            <Link to={`/publicacao/criando-um-curriculo-profissional`}>
                                <div className={style.publication_card}>
                                        <span className={style.publication_card_image}>
                                            <img src="images/publication3.png"/>
                                        </span>
                                        <h2 className={style.publication_card_title}>Criando um currículo profissional</h2>
                                        <p className={style.publication_card_description}>Nesta publicação, apresentamos um passo a passo de como criar um currículo profissional utilizando a nossa ferramenta.</p>
                                </div>
                            </Link>
                        </Col>

                        <Col lg="4">   
                            <Link to={`/publicacao/como-se-preparar-para-o-mercado-de-trabalho`}>                           
                                <div className={style.publication_card}>
                                        <span className={style.publication_card_image}>
                                            <img src="images/publication1.png"/>
                                        </span>
                                        <h2 className={style.publication_card_title}>Como se preparar para o mercado de trabalho</h2>
                                        <p className={style.publication_card_description}>Nesta postagem, você encontrará dicas valiosas sobre como se preparar para o mercado de trabalho. </p>
                                </div>
                            </Link>   
                        </Col>                        
                        
                    </Row>
                </Container>
            </div>

        </div>
    )    

}

export default Home;