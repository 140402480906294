import React, { useState } from 'react';

import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';
import style from './Objective.module.css';


function ObjectiveModal(params) {

    const [examples, setExemples] = useState(
        [
        "Buscar uma posição desafiadora em uma empresa dinâmica, onde possa aplicar minhas habilidades e conhecimentos para contribuir com o crescimento da empresa e desenvolver minha carreira profissional",    
        "Procurando por uma oportunidade desafiadora que me permita aplicar minhas habilidades excepcionais de comunicação, organização e colaboração em equipe, para alcançar metas empresariais ambiciosas e contribuir efetivamente para o sucesso da organização.",
        "Me tornar colaborador em uma empresa onde eu possa utilizar minhas habilidades e ganhar experiência enquanto contribuo para o crescimento da produtividade da empresa.",
        "Almejo integrar uma empresa inovadora e dinâmica, onde possa aplicar minhas habilidades e conhecimentos para contribuir efetivamente com o crescimento e desenvolvimento da organização, enquanto aprimoro minhas habilidades e adquiro experiência para uma carreira de sucesso.",
        "Buscar oportunidades profissionais como confeiteiro ou padeiro, onde possa aplicar minha paixão e habilidades em culinária e confeitaria para produzir produtos de qualidade excepcional e contribuir para o sucesso do negócio.",
        "Desejo fazer parte de uma empresa com uma cultura de trabalho dinâmica e colaborativa, comprometida em fornecer serviços e valores de qualidade excepcional para seus clientes. Busco contribuir para o crescimento e sucesso do negócio, aplicando minhas habilidades e experiência em um ambiente desafiador e gratificante.",
        "Desejo integrar uma empresa comprometida em fornecer produtos e serviços de qualidade excepcional para seus clientes, onde possa aplicar minha paixão por atendimento ao cliente e habilidades em resolução de problemas para garantir a satisfação do cliente e o sucesso do negócio"
    ]);

    const selectObjective = (option) => {

        let event = {
            target: {name: "objective", value: examples[option]}
        }    

        params.onChange(event);
        params.handleClose();
    }

    return ( 
        <Modal show={params.show} onHide={params.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Objetivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p><b>A definição clara dos meus objetivos profissionais é crucial. Resuma seus objetivos em até três linhas, sendo conciso e objetivo. Veja exemplos:</b></p>
                <p><span>{examples[0]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(0)}>Usar exemplo</button></p>
                <p><span>{examples[1]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(1)}>Usar exemplo</button></p>
                <p><span>{examples[2]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(2)}>Usar exemplo</button></p>
                <p><span>{examples[3]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(3)}>Usar exemplo</button></p>
                <p><span>{examples[4]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(4)}>Usar exemplo</button></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={params.handleClose}>
                 Fechar
                </Button>
            </Modal.Footer>
          </Modal>
    );
  }
  
  export default ObjectiveModal;