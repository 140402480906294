import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { Icon } from 'react-icons-kit'
import {plus} from 'react-icons-kit/icomoon/plus'
import {trashO} from 'react-icons-kit/fa/trashO'
import Pagination from 'react-bootstrap/Pagination';

import Ad from "../../../Ad";

import EducationModal from './EducationModal';


import mainStyle from '../Curriculo.module.css';
import style from './Education.module.css';


function Education(params) {
    const [items, setItems] = useState(0);
    const [active, setActive] = useState(0);

    const [showModal, setShowModal] = useState(false);
    const [validation, setValidation] = useState([]);

    const inputInstitutionName = useRef(null);
    const inputEducationName = useRef(null);
    const inputPlace = useRef(null);
    const inputStartDate = useRef(null);
    const inputEndDate = useRef(null);
    const inputActivitiesDescription = useRef(null);


    const handleModal = () => {
        setShowModal(!showModal);
    }

    const addValidation = () => {
        var newValidation = {
            institutionName: false,
            educationName: false,
            place: false,
            startDate: false,
            endDate: false,
            activitiesDescription: false
         }

         let validation_e = validation;

         validation_e.push(newValidation);

         setValidation(validation_e);

         params.onChangeValidation({education: validation_e});
    }

    useEffect(() => {
        setItems(params.data.educations.length);
        setValidation(params.validation.education);
        if(params.data.educations.length > 0)
        setActive(0);
    }, []);

    useEffect(() => {
        if(items > 0 && params.width >= 990)
            inputInstitutionName.current.focus();
    }, [items]);

    const addItem = () => {
      if(items < 9) {
         // setting a new experience
         var educations = params.data.educations;

         var newEducation = {
            institutionName: "",
            educationName: "",
            place: "",
            startDate: "",
            endDate: "",
            activitiesDescription: ""
         }
 
         educations.push(newEducation);
         addValidation();
 
         let event = {
             target: {name: "educations", value: educations}
         }
 
        params.onChange(event);

        setItems(items + 1);
        setActive(items);
      }
    }    

    const cleanValidation = (index) => {
        let arrValidation = validation;
        arrValidation[index].institutionName = false;
        arrValidation[index].educationName = false;
        arrValidation[index].place = false;
        arrValidation[index].startDate = false;
        arrValidation[index].endDate = false;
        arrValidation[index].activitiesDescription = false;
        setValidation(arrValidation);
    }
    
    const rmItem = () => {
      if(items > 0) {

        // setting a new experience
        var educations = params.data.educations;

        cleanValidation(active);
        educations.splice(active, 1);

        let event = {
            target: {name: "educations", value: educations}
        }

        params.onChange(event);

        setItems(items - 1);
        let newActive = active == 0 ? active : active - 1;
        setActive(newActive);
      }
    } 

    useEffect(() => {
    }, [active]);

    const changeActive = (index) => {
      setActive(index);
    }

    const handleErrorUpdate = (e) => {

        let arrValidation = validation;

        if(e) {
            if(e.target.name.includes(".")) {
                arrValidation[active][e.target.name.split(".")[1]] = false;
                setValidation(arrValidation);
            } else {
                arrValidation[active][e.target.name] = false;
                setValidation(arrValidation);    
            }
        }

        let event = {
            target: {name: "educations", value: params.data.educations}
        }

        params.onChange(event);
    }

    const handleEducationChange = (e) => {
        // setting a new experience
        var educations = params.data.educations;

        educations[active][e.target.name] = e.target.value;

        let event = {
            target: {name: "educations", value: educations}
        }

        handleErrorUpdate(e);

        params.onChange(event);
    }

    const renderItems = () => {
        let pagination = [];

        for(var i = 0; i < items; i++) {
          let key = i;
          pagination.push(
              <Pagination.Item 
                    key={key} 
                    onClick={() => changeActive(key)}
                    active={key == active}>
                {i + 1}
              </Pagination.Item>
          )
        }

        return (
          pagination
        )
        
    }

    const handleNextStep = () => {
        let isNotValid = false;
        let arrValidation = validation;
        let indexErrorValidation = -1;

        for(let i = 0; i < params.data.educations.length; i++) {

             if(params.data.educations[i].endDate == ""  && params.data.educations[i].startDate != "" && params.data.educations[i].place != "" && params.data.educations[i].educationName != "" && params.data.educations[i].institutionName != "") {
                arrValidation[i].endDate = true;       
                inputEndDate.current.focus();
                isNotValid = true;
             }

             if(params.data.educations[i].startDate == "" && params.data.educations[i].place != "" && params.data.educations[i].educationName != ""  && params.data.educations[i].institutionName != "") {
                arrValidation[i].startDate = true;      
                inputStartDate.current.focus(); 
                isNotValid = true;
             }

             if(params.data.educations[i].place == "") {
                arrValidation[i].place = true;       
                inputPlace.current.focus();
                isNotValid = true;
             }

             if(params.data.educations[i].educationName == "") {
                arrValidation[i].educationName = true;
                //inputEducationName.currrent.focus(); 
                inputEducationName.current.focus();     
                isNotValid = true;
             }

             if(params.data.educations[i].institutionName == "") {
                arrValidation[i].institutionName = true;     
                inputInstitutionName.current.focus();  
                isNotValid = true;
             }

             if(isNotValid) {
                 indexErrorValidation = i;
                 break;
             }
         }

        if(isNotValid) {            
            setValidation(arrValidation);
            handleErrorUpdate();
            setActive(indexErrorValidation);
            return;
        }

        params.nextStep({education: validation});
    }

    const handlePreviusStep = () => {
        params.previousStep({education: validation});
    }

    return (
        <>
        <EducationModal show={showModal} handleClose={handleModal} />
        <div className={mainStyle.component}>
            <h1 className={mainStyle.component_title}>Formação Acadêmica </h1>
            <div>
                <Badge variant="secondary" className={mainStyle.badge_info} onClick={handleModal}>AJUDA</Badge>
                {params.width < 990 &&
                <Badge variant="secondary" className={mainStyle.badge_download} onClick={params.showCv} >VER CURRÍCULO</Badge>
                }
                   
                   <div>                    
                    <Button disabled={items >= 10} variant="secondary" size="sm" onClick={addItem} className={style.btn_add}>
                        <Icon icon={plus} size={12} /> Adicionar
                    </Button>

                    {items > 0 &&
                    <Button disabled={items < 1} variant="danger" size="sm" onClick={rmItem}>
                        <Icon icon={trashO} size={12} /> Remover
                    </Button>
                    }
                  </div>

                  {items > 0 &&   
                    <div className={style.navigation}>
                    <Pagination size="md">                   
                        {
                            renderItems()
                        }
                    </Pagination>
                    </div>
                }
            </div>

            <Form className={classNames(mainStyle.inputSection, mainStyle.inputSectionOverFlow)}>  
                {items > 0 &&
                <>
                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation[active].institutionName ? style.inputError : ""}>Nome da Instituição de Ensino:</Form.Label>
                        <Form.Control autoComplete="nope" ref={inputInstitutionName}  value={params.data.educations[active].institutionName} onChange={handleEducationChange} name="institutionName" type="text" placeholder="Digite o nome da instituição..." isInvalid={validation[active].institutionName} />
                        <Form.Control.Feedback type="invalid">
                            Digite o nome da instituição
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation[active].educationName ? style.inputError : ""}>Nome do Curso:</Form.Label>
                        <Form.Control autoComplete="nope" ref={inputEducationName} value={params.data.educations[active].educationName} onChange={handleEducationChange} name="educationName" type="text" placeholder="Digite o nome do curso..." isInvalid={validation[active].educationName} />
                        <Form.Control.Feedback type="invalid">
                            Digite o nome da formação
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>  

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation[active].place ? style.inputError : ""}> Local:</Form.Label>
                        <Form.Control autoComplete="nope" ref={inputPlace} type="text" value={params.data.educations[active].place} onChange={handleEducationChange} name="place" placeholder="Cidade, estado, país etc..." isInvalid={validation[active].place}   />
                        <Form.Control.Feedback type="invalid">
                            Digite o local de realização da formação
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>

                
                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation[active].startDate ? style.inputError : ""}>Data de Início:</Form.Label>
                        <Form.Control autoComplete="nope" ref={inputStartDate} type="date" value={params.data.educations[active].startDate} onChange={handleEducationChange} name="startDate" placeholder="Data de início no curso..." isInvalid={validation[active].startDate}  />
                        <Form.Control.Feedback type="invalid">
                            Digite a data de início
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation[active].endDate ? style.inputError : ""}>Data do fim/previsto da formação:</Form.Label>
                        <Form.Control autoComplete="nope" ref={inputEndDate} type="date" value={params.data.educations[active].endDate} onChange={handleEducationChange} name="endDate" placeholder="Digite a data de saída..." isInvalid={validation[active].endDate}  />
                        <Form.Control.Feedback type="invalid">
                            Digite a data de saída
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label>Atividades realizadas:</Form.Label>
                        <Form.Control autoComplete="nope" ref={inputActivitiesDescription} value={params.data.educations[active].activitiesDescription} 
                                      onChange={handleEducationChange} 
                                      as="textarea" 
                                      rows={4}
                                      name="activitiesDescription" 
                                      type="text"  
                                      placeholder="Digite as atividades realizadas durante a formação..." 
                                      isInvalid={false} 
                        />
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup> 
                </>
            }   
            </Form>
            <div className={mainStyle.buttonSection}>    
                    <Button onClick={handlePreviusStep} className={classNames(mainStyle.btn_control, "float-left", mainStyle.btn_info)} variant="dark">Anterior</Button>
                    <Button onClick={handleNextStep} className={classNames(mainStyle.btn_control, "float-right")} variant="dark">Próximo</Button>
            </div>   

        </div>
        </>
    );
  }
  
  export default Education;