import React, { useState, useEffect, useRef}  from 'react';

import classNames from 'classnames';

import { Container, Row, Button, Card, Form} from 'react-bootstrap';
import axios from 'axios';

import style from "./PasswordRecovery.module.css";

import {
    BrowserRouter as Router,
    useHistory
  } from "react-router-dom";

import { useQuery } from "../../../Utils/RouteUtil";

function PasswordRecovery() {

    useEffect(() => {
        inputPassword.current.focus();
    },[]);

    let history = useHistory();
    let query = useQuery();

    const inputPassword = useRef(null);
    const inputRepeatPassword = useRef(null);

    const [formValidation, setFormValidation] = useState({
        password: false,
        passwordErrorMessage: null,
        repeatPassword: false,
        repeatPasswordErrorMessage: null     
     });

    const checkValidation = (e) => {
        setFormValidation({
            ...formValidation,
            [e.target.name]: false
        })
    }

    const onChange = (e) => {
        checkValidation(e);
        setFormInput({
            ...formInput,
            [e.target.name]: e.target.value
        })
    }

    const onHandleErrorMessage = (e) => {
        setFormValidation({
            ...formValidation,
            [e.name]: true,
            [e.errorField]: e.errorMessage 
        })
    }

    const [formInput, setFormInput] = useState({
        password: "",
        repeatPassword: ""
     });

    const validatePassword = (password) => {
        if(password.length == "") return "Por favor, digite sua senha.";
        if(password.length < 8) return "Senha deve ter pelo menos 8 caracteres.";
        return "";
    }

    const onSubmit = (e) => {

        let error = false;
        let errors = {};

        if(formInput.password == "") { errors.password = true; error = true; errors.passwordErrorMessage = "Por favor, digite a senha." }
        if(formInput.repeatPassword == "") { errors.repeatPassword = true; error = true; errors.repeatPasswordErrorMessage = "Por favor, digite a senha novamente." }

        if(formInput.repeatPassword == "") inputRepeatPassword.current.focus();
        
        if(validatePassword(formInput.password) !== "") {
            error = true;
            errors.password = true;
            errors.passwordErrorMessage = validatePassword(formInput.password);
            inputPassword.current.focus();
        }

         // passwords not equal
         if(formInput.password !== formInput.repeatPassword) {
            error = true;
            errors.password = true;
            errors.passwordErrorMessage = "Senhas devem ser iguais.";
            errors.repeatPassword = true;
            errors.repeatPasswordErrorMessage = "Senhas devem ser iguais.";
            inputPassword.current.focus();
        }

        setFormValidation({...formValidation, ...errors});

        if(error) return;

        history.push("/entrar");

        /*axios.post(process.env.REACT_APP_API_ENDPOINT + '/login', {
            email: formInput.email,
            password: formInput.password
        })
        .then(function (response) {



            console.log(response);
        })
        .catch(function (error) {

            if(error.response) {
                const errorObject = {
                    name: "email",
                    errorField: "emailErrorMessage",
                    errorMessage: error.response.data.message
                }
                onHandleErrorMessage(errorObject);
            }

            console.log(error.response.data.message);
        });    */


    }
 
    return (
        <div className={style.login_page}>
            <Container className={style.container_login}>
                <Card style={{ width: '28rem'}}>
                    <Card.Body>
                        <Card.Title className={style.card_title}>Recuperar senha</Card.Title>
                        <Card.Text style={{ marginTop: '22px'}}>
                           <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control className={style.input_login} onChange={onChange} name="password"  ref={inputPassword} type="password" placeholder="Senha"  value={formInput.email} />
                                    {formValidation.email &&
                                        <Form.Text className={style.error_text} muted>
                                         {formValidation.emailErrorMessage}
                                        </Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control className={style.input_login} onChange={onChange} name="repeatPassword"  ref={inputRepeatPassword} type="password" placeholder="Repetir senha"  value={formInput.email} />
                                    {formValidation.email &&
                                        <Form.Text className={style.error_text} muted>
                                         {formValidation.emailErrorMessage}
                                        </Form.Text>
                                    }
                                </Form.Group>
                            </Form>
                        </Card.Text>
                        <div className={style.button_container}>
                          <Button variant="success" size="lg" onClick={onSubmit} className={style.register_button}>Enviar</Button>
                        </div>                        
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )    

}

export default PasswordRecovery;