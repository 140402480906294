import React, { useState, useEffect, useRef}  from 'react';

import { Container, Breadcrumb } from "react-bootstrap";

import style from "./Post.module.css";
import BoasPriticasParaUmaEntrevista from './BoasPraticasParaUmaEntrevista';
import CriandoUmCurriculoProfissional from './CriandoUmCurriculoProfissional';
import ComoSePrepararParaOMercadoTrabalho from "./ComoSePrepararParaOMercadoTrabalho";

import {
    useParams,
    useRouteMatch,
    Link
  } from "react-router-dom";

function Post() {

    let { path, url } = useRouteMatch();

    let { code } = useParams();
  
    const postToRender = () => {
        switch(code) {
            case "boas-praticas-para-uma-entrevista-bem-sucedida": return (<BoasPriticasParaUmaEntrevista />)
            case "criando-um-curriculo-profissional": return (<CriandoUmCurriculoProfissional />)
            case "como-se-preparar-para-o-mercado-de-trabalho": return (<ComoSePrepararParaOMercadoTrabalho />)
        }
    }
  
    return (
        <Container className={style.publication_body}>                
           <Breadcrumb className={style.breadcrumb}>
                <Breadcrumb.Item><Link to={`/`}>Início</Link></Breadcrumb.Item>
                <Breadcrumb.Item>
                 <Link to={`/publicacao`}>Publicações</Link>
                </Breadcrumb.Item>
            </Breadcrumb>
          {postToRender()}           
          </Container>
      );
  }

export default Post;