import React, { useState, useEffect, useRef}  from 'react';

import classNames from 'classnames';

import { Container, Row, Button, Card, Form} from 'react-bootstrap';
import axios from 'axios';

import style from "./PasswordRecoveryRequest.module.css";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useHistory
  } from "react-router-dom";

function PasswordRecoveryRequest() {

    useEffect(() => {
        inputEmail.current.focus();
    },[]);

    const inputEmail = useRef(null);

    const [formValidation, setFormValidation] = useState({
        email: false      
     });

    const checkValidation = (e) => {
        setFormValidation({
            ...formValidation,
            [e.target.name]: false
        })
    }

    const onChange = (e) => {
        checkValidation(e);
        setFormInput({
            ...formInput,
            [e.target.name]: e.target.value
        })
    }

    const onHandleErrorMessage = (e) => {
        setFormValidation({
            ...formValidation,
            [e.name]: true,
            [e.errorField]: e.errorMessage 
        })
    }

    const [formInput, setFormInput] = useState({
        email: ""
     });

    const onSubmit = (e) => {

        let error = false;
        let errors = {};

        if(formInput.email == "") { errors.email = true; error = true; errors.emailErrorMessage = "Por favor, digite seu e-mail" }

        if(formInput.email == "") inputEmail.current.focus();
        
        setFormValidation({...formValidation, ...errors});

        if(error) return;

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/login', {
            email: formInput.email,
            password: formInput.password
        })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {

            if(error.response) {
                const errorObject = {
                    name: "email",
                    errorField: "emailErrorMessage",
                    errorMessage: error.response.data.message
                }
                onHandleErrorMessage(errorObject);
            }
        });    


    }
 
    return (
        <div className={style.login_page}>
            <Container className={style.container_login}>
                <Card style={{ width: '28rem'}}>
                    
                    <Card.Body>
                        <Card.Title className={style.card_title}>Recuperar senha</Card.Title>
                        <Card.Text style={{ marginTop: '22px'}}>
                            <p>
                            Insira seu endereço de e-mail e clique no botão ENVIAR abaixo, e enviaremos uma senha por e-mail para você. Se você não receber um e-mail em alguns instantes, verifique a pasta de SPAM.
                            </p> 
                           <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control className={style.input_login} onChange={onChange} name="email"  ref={inputEmail} type="email" placeholder="Email"  value={formInput.email} />
                                    {formValidation.email &&
                                        <Form.Text className={style.error_text} muted>
                                         {formValidation.emailErrorMessage}
                                        </Form.Text>
                                    }
                                </Form.Group>
                            </Form>
                        </Card.Text> 
                        <div className={style.button_container}>
                          <Button variant="success" size="lg" onClick={onSubmit} className={style.register_button}>Enviar</Button>
                        </div>                     
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )    

}

export default PasswordRecoveryRequest;