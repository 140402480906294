import React, { useState, useEffect, useRef}  from 'react';

import classNames from 'classnames';

import { Container, Row, Button, Card, Form} from 'react-bootstrap';
import axios from 'axios';

import style from "./Login.module.css";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useHistory
  } from "react-router-dom";



function Login() {

    useEffect(() => {
        inputEmail.current.focus();
    },[]);

    const inputEmail = useRef(null);
    const inputPassword = useRef(null);


    const [formValidation, setFormValidation] = useState({
        email: false,
        emailErrorMessage: null,
        password: false        
     });

    const checkValidation = (e) => {
        setFormValidation({
            ...formValidation,
            [e.target.name]: false
        })
    }

    const onChange = (e) => {
        checkValidation(e);
        setFormInput({
            ...formInput,
            [e.target.name]: e.target.value
        })
    }

    const onHandleErrorMessage = (e) => {
        setFormValidation({
            ...formValidation,
            [e.name]: true,
            [e.errorField]: e.errorMessage 
        })
    }

    const [formInput, setFormInput] = useState({
        email: "",
        password: "",
     });

    const onSubmit = (e) => {

        let error = false;
        let errors = {};

        if(formInput.email == "") { errors.email = true; error = true; errors.emailErrorMessage = "Por favor, digite seu e-mail" }
        if(formInput.password == "") { errors.password = true; error = true; }

        
        if(formInput.password == "") inputPassword.current.focus();
        if(formInput.email == "") inputEmail.current.focus();
        
        setFormValidation({...formValidation, ...errors});

        if(error) return;

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/login', {
            email: formInput.email,
            password: formInput.password
        })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {

            if(error.response) {
                const errorObject = {
                    name: "email",
                    errorField: "emailErrorMessage",
                    errorMessage: error.response.data.message
                }
                onHandleErrorMessage(errorObject);
            }
        });    


    }
 
    return (
        <div className={style.login_page}>
            <Container className={style.container_login}>
                <Card style={{ width: '28rem'}}>
                    
                    <Card.Body>
                        <Card.Title className={style.card_title}>Entrar no Gerar Currículo</Card.Title>
                        <Card.Text style={{ marginTop: '22px'}}>
                           <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    {/* <Form.Label className={style.input_label}>E-mail:</Form.Label> */}
                                    <Form.Control className={style.input_login} onChange={onChange} name="email"  ref={inputEmail} type="email" placeholder="Email"  value={formInput.email} />
                                    {formValidation.email &&
                                        <Form.Text className={style.error_text} muted>
                                         {formValidation.emailErrorMessage}
                                        </Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    {/* <Form.Label className={style.input_label}>Senha:</Form.Label> */}
                                    <Form.Control className={style.input_login} onChange={onChange} name="password"  ref={inputPassword} type="password" placeholder="Senha" value={formInput.password} />
                                    {formValidation.password &&
                                    <Form.Text className={style.error_text} muted>
                                        Por favor, digite sua senha.
                                    </Form.Text>
                                    }
                                </Form.Group>

                            </Form>
                        </Card.Text>
                        <div className="d-grid gap-2">
                          <Button variant="info" size="lg" onClick={onSubmit} className={style.login_button}>Entrar</Button>
                        </div>

                        <Link to={`/recuperar_conta`}>
                        <p className={style.senha_recovery}>Esqueceu a senha?</p></Link>

                        <hr />

                        <div className={style.button_container}>
                          <Link to={`/registrar`}>
                          <Button variant="success" size="lg" className={style.register_button}>Criar nova Conta</Button></Link>
                        </div>
                        
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )    

}

export default Login;