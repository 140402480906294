import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import OriginInfoModal from './OriginInfoModal';

import useWindowDimensions from '../../useWindowDimensions';

import Ad from "../../../Ad";

import mainStyle from '../Curriculo.module.css';
import style from './OriginInfo.module.css';

import {
    Link
  } from "react-router-dom";


function OriginInfo(params) {

    const { height, width } = useWindowDimensions();

    const [showModal, setShowModal] = useState(false);

    const handleModal = () => {
        setShowModal(!showModal);
    }

    const inputNationality = useRef(null);

    useEffect(() => {
        if(params.width >= 990)
             inputNationality.current.focus();
    }, []);

    return (
        <>
        <OriginInfoModal show={showModal} handleClose={handleModal} />
        <div className={mainStyle.component}>
            <h1 className={mainStyle.component_title}>Dados de Origem </h1>
            <div>
                <Badge variant="secondary" className={mainStyle.badge_info} onClick={handleModal}>AJUDA</Badge>
                {params.width < 990 &&
                <Badge variant="secondary" className={mainStyle.badge_download} onClick={params.showCv} >VER CURRÍCULO</Badge>
                }
            </div>

            <Form onSubmit={e => { e.preventDefault(); }} className={classNames(mainStyle.inputSection, mainStyle.inputSectionOverFlow)}>    
                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label>Nacionalidade:</Form.Label>
                        <Form.Control maxLength={15} ref={inputNationality} autocomplete="off" value={params.data.nationality} onChange={params.onChange} name="nationality" type="text" placeholder="Digite sua nacionalidade..." isInvalid={false} />
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>  

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label>Naturalidade:</Form.Label>
                        <Form.Control maxLength={15} value={params.data.placeOfBirth} autocomplete="off" onChange={params.onChange} name="placeOfBirth" type="text" placeholder="Digite sua naturalidade..." isInvalid={false} />
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>    

                </Form>

                <div className={mainStyle.buttonSection}>  
                    <Button onClick={params.previousStep} className={classNames(mainStyle.btn_control, "float-left", mainStyle.btn_info)} variant="dark">Anterior</Button>
                    <Button onClick={params.nextStep} className={classNames(mainStyle.btn_control, "float-right")} variant="dark">Próximo</Button>
                </div>  
        
        </div>
        </>
    );
  }
  
  export default OriginInfo;